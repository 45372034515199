<template>
	<div class="container">
		<el-card>
			<div class="flexRow spaceBetween">
				<div>自动打标签模板</div>
				<el-link type="info" style="color: #666;" href="https://jusnn6k8al.feishu.cn/docs/doccnUDwHRkxqveFnoAf9IFBQ9c" target="_blank">
					<i class="el-icon-tickets" style="margin-right: 3px;"></i>自动打标签功能使用说明
				</el-link>
			</div>
			<div class="flexRow" style="margin-top:15px;overflow:hidden">
				<div @click="templateVisible=true">
					<labeltem ispush></labeltem>
				</div>
				<div class="flexRow" style="overflow:hidden;overflow-x:auto;margin-left:20px">
					<div class="flexRow">
						<labeltem class="labeltem-class" v-for="item in templateList" :key="item.templateId"
							:itemData="item" @click="toDetail(item)"></labeltem>
					</div>
					<div class="bluelabel" style="cursor: pointer;flex-shrink:0"  @click="$router.push({name: 'marketadvice', query:{type:2}})">
						<span>全部模板</span>
						<i class="el-icon-right"></i>
					</div>
				</div>
			</div>
		</el-card>
		<el-card style="margin-top:10px">
			<div class="search">
				<div class="search" style="margin-right: 150px;">
					<div class="search-item">
						<span class="search-title">关键字:</span>
						<el-input placeholder="规则名称" v-model="keywords" style="width: 200px;margin-right: 10px;">
						</el-input>
					</div>
					<div class="search-item">
						<span class="search-title">规则状态:</span>
						<el-select v-model="state" placeholder="请选择" style="width: 200px;margin-right: 5px;">
							<el-option v-for="item in stateList" :key="item.value" :label="item.lable"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="search-item">
						<span class="search-title">更新方式:</span>
						<el-select v-model="updatestyle" placeholder="请选择" style="width: 200px;margin-right: 5px;">
							<el-option v-for="item in updatestylelist" :key="item.value" :label="item.lable"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="search-item">
						<div>
							<el-button type="primary" style="margin-right: 15px;" @click="pageIndex=1;getList()">查询
							</el-button>
						</div>
					</div>
				</div>
			</div>


			<el-table :data="tableData" style="width: 100%" v-loading="loading">
				<el-table-column prop="Name" label="规则名称">
					<template slot-scope="scope">
						<div class="rule-name">{{scope.row.Name}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="tag" label="匹配标签">
					<template slot-scope="scope">
						<el-popover title="" width="400" placement="right" trigger="hover">
							<div style="display: inline-block;max-height: 400px;overflow: auto;">
								<el-tag style="margin-right: 5px;margin-bottom: 5px;" type="info"
									v-for="(item, index) in scope.row.TagRuleConfigTagDetailList" :key="index">
									{{item.TagDetailName}}
								</el-tag>
							</div>

							<span slot="reference">
								<el-tag style="margin-right: 5px;margin-bottom: 5px;" type="info"
									v-for="(item, index) in scope.row.TagRuleConfigTagDetailList" :key="index"
									v-if="index<2">
									{{item.TagDetailName}}
								</el-tag>
								<el-tag type="info" v-if="scope.row.TagRuleConfigTagDetailList.length>2">...</el-tag>
							</span>
						</el-popover>
					</template>
				</el-table-column>
				<el-table-column prop="UpdateWayValue" label="更新方式">
				</el-table-column>
				<el-table-column prop="StateValue" label="规则状态">
				</el-table-column>
				<el-table-column prop="LastUpdateTime" label="最近一次更新时间">
				</el-table-column>
				<el-table-column prop="AddTime" label="创建时间">
				</el-table-column>
				<el-table-column prop="control" label="操作" width="190">
					<template slot-scope="scope">
						<el-button type="text" @click="handleToDetail(scope.row)">详情</el-button>
						<el-button type="text" v-if="scope.row.State == 1" @click="handleToStop(scope.row)">停用
						</el-button>
						<buttonPermissions :datas="'autoAddTagUpdateBtn'">
							<el-button type="text" style="margin-left: 10px;" v-if="scope.row.UpdateWay===0 && scope.row.State == 1"
								@click="handleUpdate(scope.row)">更新</el-button>
						</buttonPermissions>
						<el-button type="text" style="margin-left: 10px;" @click="handleCopy(scope.row)">复制</el-button>
						<el-button type="text" v-if="scope.row.State != 1" @click="handleDelete(scope.row)"><span
								style="color: #F56C6C;">删除</span></el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination" v-if="total">
				<div class="left">
				</div>
				<el-pagination :page-size="pageSize" :total="total" :current-page="pageIndex"
					:page-sizes="[10, 20, 30, 40, 50]" @size-change="sizeChange" @current-change="currentChange"
					layout="total, sizes, prev, pager, next, jumper">
				</el-pagination>
			</div>

		</el-card>

		<el-dialog :visible.sync="templateVisible" width="960px">
			<div slot="title" class="header-title">选择模板</div>
			<div class="dialog-content" style="margin-top: -30px;">
				<div class="dialog-title">不使用模板，自定义创建 <span>不使用模板，自定义本次自动打标签的规则</span></div>
				<div>
					<labeltem ispush @click="handleToDetail(null)"></labeltem>
				</div>
				<div class="dialog-title">客户分类 <span>针对客户消费次数、消费金额、充值、生命周期等对客户进行分类</span></div>
				<div class="dialog-list">
					<div class="dialog-item" v-for="item in templateList1" :key="item.templateId">
						<labeltem :itemData="item" @click="toDetail(item)"></labeltem>
					</div>
				</div>
				<div class="dialog-title">商品类标签 <span>针对客户购买商品、浏览商品、分享商品等行为，给客户打上标签</span></div>
				<div class="dialog-list">
					<div class="dialog-item" v-for="item in templateList2" :key="item.templateId">
						<labeltem :itemData="item" @click="toDetail(item)"></labeltem>
					</div>
				</div>
				<div class="dialog-title">营销类标签 <span>针对客户参与的营销活动次数，给客户打上标签</span></div>
				<div class="dialog-list">
					<div class="dialog-item" v-for="item in templateList3" :key="item.templateId">
						<labeltem :itemData="item" @click="toDetail(item)"></labeltem>
					</div>
				</div>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import {
		tagRuleConfiglist,
		tagRuleConfigemodifyState,
		tagRuleConfigemodifydelete,
		tagRuleConfigupdateTag,
		getTemplateParam
	} from '@/api/sv1.0.0.js';
	
	import {
		templateList
	} from './js/normalTemplate.js'
	
	import labeltem from "./components/labeltem";
	import buttonPermissions from '@/components/buttonPermissions';
	
	
	export default {
		components: {
			labeltem,
			buttonPermissions
		},
		data() {
			return {
				keywords: '',
				stateList: [{
						lable: "全部",
						value: null
					},
					{
						lable: "启用中",
						value: 1
					},
					{
						lable: "已停用",
						value: 2
					}
				],
				state: null,
				updatestyle: 0,
				updatestylelist: [{
					value: 0,
					lable: '全部',
					params: {
						UpdateWay: null,
						AutoUpdatePeriodType: null
					}
				}, {
					value: 1,
					lable: '手动更新',
					params: {
						UpdateWay: 0,
						AutoUpdatePeriodType: null
					}
				}, {
					value: 2,
					lable: '自动更新：每天',
					params: {
						UpdateWay: 1,
						AutoUpdatePeriodType: 0
					}
				}, {
					value: 3,
					lable: '自动更新：每周',
					params: {
						UpdateWay: 1,
						AutoUpdatePeriodType: 1
					}
				}, {
					value: 4,
					lable: '自动更新：每月',
					params: {
						UpdateWay: 1,
						AutoUpdatePeriodType: 2
					}
				}],
				tableData: [],
				pageIndex: 1,
				pageSize: 20,
				total: 0,
				loading: false,

				templateList: [],
				templateList1: [],
				templateList2: [],
				templateList3: [],
				templateVisible: false
			};
		},
		created() {
			templateList.map(item=>{
				if ([5,2,11,3].indexOf(item.templateId) > -1){
					this.templateList.push(item);
				}
				if ([1,2,3,4,5,6,7,8,9,10,11].indexOf(item.templateId) > -1){
					this.templateList1.push(item);
				}
				if ([12,13,14].indexOf(item.templateId) > -1){
					this.templateList2.push(item);
				}
				if ([15,16,17].indexOf(item.templateId) > -1){
					this.templateList3.push(item);
				}
			})
		},
		beforeMount() {
			this.getList()
		},
		methods: {
			async getList() {
				console.log(this.updatestylelist[this.updatestyle])
				this.loading = true;
				try {
					const res = await tagRuleConfiglist({
						...this.updatestylelist[this.updatestyle].params,
						Skip: (this.pageIndex - 1) * this.pageSize,
						Take: this.pageSize,
						TagDetailId: this.$route.query.TagDetailId ? this.$route.query.TagDetailId : '',
						Keywords: this.keywords,
						State: this.state,

					})

					if (res.IsSuccess) {
						this.tableData = res.Result.Results;
						this.total = res.Result.Total;
					}
				} catch (e) {

				} finally {
					this.loading = false;
				}
			},
			sizeChange(value) {
				this.pageSize = value;
				this.getList();
			},
			currentChange(index) {
				this.pageIndex = index;
				this.getList()
			},
			handleToDetail(record) {
				this.$router.push({
					name: 'autoAddTagEdit',
					query: {
						id: record ? record.Id : ''
					}
				})
			},
			handleCopy(record) {
				this.$router.push({
					name: 'autoAddTagEdit',
					query: {
						copyId: record.Id
					}
				})
			},

			//停用
			handleToStop(record) {
				this.$confirm('规则停用后，将中断打标签进程，但不影响给已经符合规则的客户打标签。是否确认停用规则【' + record.Name + '】？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					confirmButtonClass:'btnclass',
					cancelButtonClass:'btnclass',
				}).then(() => {
					this.sureStop(record)
				}).catch(() => {});
			},
			async sureStop(record) {
				try {
					const res = await tagRuleConfigemodifyState({
						TagRuleConfigId: record.Id,
						State: 2
					})

					this.getList()
				} catch (e) {
					//TODO handle the exception
				}
			},

			//删除
			handleDelete(record) {
				this.$confirm('规则删除后，不影响符合规则并被打上标签的客户。是否确认删除规则【' + record.Name + '】？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					confirmButtonClass:'btnclass',
					cancelButtonClass:'btnclass',
				}).then(() => {
					this.sureDelete(record)
				}).catch(() => {});
			},
			async sureDelete(record) {
				try {
					const res = await tagRuleConfigemodifydelete({
						TagRuleConfigId: record.Id
					})

					this.getList()
				} catch (e) {
					//TODO handle the exception
				}
			},
			async handleUpdate(record) {
				try {
					const res = await tagRuleConfigupdateTag({
						TagRuleConfigId: record.Id
					})
					this.$message.success('操作成功， 开始更新数据')
					this.getList();
				} catch (e) {}
			},
			toDetail(record) {
				this.$router.push({
					name: 'autoAddTagEdit',
					query: {
						templateId: record.templateId
					}
				})
			},
			
		}
	}
</script>

<style lang="less" scoped>
	.container {
		font-size: 14px;

		.search {
			position: relative;
			margin-bottom: 15px;

			display: flex;
			flex-wrap: wrap;

			.search-item {
				margin-bottom: 10px;
				margin-right: 15px;
				display: flex;

				.search-title {
					padding-top: 10px;
					margin-right: 5px;
					font-size: 14px;
					font-weight: bold;
					color: #606266;
				}
			}

			.help {
				position: absolute;
				right: 0;
				top: 5px;
				white-space: nowrap;
				color: #666;
				cursor: pointer;
			}
		}

		.rule-detail {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.rule-name {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.pagination {
			display: flex;
			margin-top: 15px;
			justify-content: space-between;
			align-items: center;
		}
	}

	.flexRow {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		// justify-content: space-between;
	}
	.spaceBetween{
		justify-content: space-between;
	}

	.labeltem-class {
		margin-right: 20px;
	}

	.bluelabel {
		width: 130px;
		height: 170px;
		background: #409eff;
		border-radius: 3px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		color: white;
	}

	
	.dialog-content{
		height: 70vh;
		overflow: auto;
	}
	.dialog-title{
		font-size: 16px;
		font-weight: bold;
		margin: 30px 0 15px;
		
		span{
			font-size: 14px;
			color: #999;
			font-weight: 400;
		}
	}
	.dialog-list{
		display: flex;
		flex-wrap: wrap;
		
		.dialog-item{
			margin-right: 20px;
			margin-bottom: 20px;
		}
	}
	.header-title{
		font-size: 18px;
		border-bottom: 1px solid #eee;
		padding-bottom: 20px;
		padding-top: 20px;
		padding-left: 20px;
		box-shadow: 0 0px 10px 0px rgba(0,0,0,.1);
		margin-left: -20px;
		margin-top: -20px;
		margin-right: -20px;
	}
</style>
<style>
.btnclass{
		/* font-size: 14px; */
	}
</style>
